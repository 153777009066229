@charset "UTF-8";
.bg-nacre {
  background-color: #F4F1EA;
}

.bg-miel {
  background: #DEA95B;
}

html {
  font-size: clamp(12px, 0.75vw + 9px, 15px);
}

body {
  font-family: "Manrope", sans-serif;
  font-size: clamp(12px, 0.23vw + 0.71rem, 1rem);
  color: #88330D;
}

a {
  color: inherit;
}
a:hover {
  color: inherit;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-family: "Lora", sans-serif;
  line-height: 1.2;
}

h1, h2 {
  font-weight: bold !important;
  font-style: italic !important;
}

h1 {
  font-size: clamp(17px, 0.23vw + 0.71rem, 1.3rem);
}

h2 {
  font-size: clamp(17px, 0.23vw + 0.71rem, 1.3rem);
  margin-bottom: 0;
}

h3 {
  font-size: 1.1rem;
}

button:focus {
  outline: none;
}

.cont {
  margin-bottom: 80px;
  max-width: 1920px;
  margin: auto;
}

.grand-btn {
  width: 350px;
}

#map {
  height: 400px;
}

.card:hover {
  transform: scale(1.02);
  transition: all linear 200ms;
  z-index: 1;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  flex-basis: 250px;
  align-items: flex-start;
  justify-content: center;
}

.product {
  box-sizing: border-box;
  float: left;
  width: 33%;
  padding: 10px 20px;
  text-indent: 0;
}

.product-show {
  width: 66%;
}

.product-header {
  text-align: center;
  padding: 5px 0;
  height: 3em;
  font-size: 1.2em;
  font-weight: 500;
  text-indent: 0;
  background-color: rgba(192, 192, 192, 0.6784313725);
}

.product-show-img {
  width: 50%;
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
}

.product-img {
  width: 100%;
}

.product-footer {
  float: left;
  width: 100%;
}

.bondecommande {
  width: 100%;
  padding-bottom: 20px;
}

.bondecommande-titre {
  color: rgb(49, 49, 49);
  margin-left: 2rem;
}

.text {
  display: none;
}

.title:target + .text {
  display: block;
}

/* Suppression des flèches sur les input de type number.
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.list-group-item.active {
  background-color: grey;
  border-color: grey;
}

.dropdown-item {
  color: #88330D;
}
.dropdown-item:active {
  background-color: #ffffff;
  color: #88330D;
}

label.required:after {
  content: " *";
}

.mb-5 {
  margin-bottom: 3rem;
}

menu {
  max-width: 1546px;
  margin: 0 auto;
  padding: 20px 5px 30px 5px;
}

.footer {
  padding: 2rem 2rem 2rem 2rem;
  /* haut | droit | bas | gauche */
  color: #88330D;
}
.footer-container {
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 1015px) {
  .footer-container {
    flex-direction: column;
  }
}
.footer a {
  color: #88330D;
  text-decoration: underline;
  line-height: 38px;
}
.footer h2 {
  font-size: 1.2rem;
  margin-bottom: 22px;
}
.footer-2columns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
@media (max-width: 1015px) {
  .footer-2columns {
    width: 100%;
    justify-content: flex-start;
    gap: 80px;
  }
}
.footer-columns {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
@media (max-width: 1015px) {
  .footer-columns {
    flex-direction: column;
    margin-top: 34px;
  }
}
.footer-column {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1015px) {
  .footer-column {
    margin-top: 34px;
  }
}
.footer-CGV {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  font-weight: bold;
  margin-top: 40px;
  align-items: center;
}
@media (max-width: 1015px) {
  .footer-CGV {
    flex-direction: column;
    margin-top: 40px;
  }
}
.footer-logo img {
  width: clamp(188px, 0.5vw + 188px, 235px);
}

.button {
  margin: 5px 5px;
  border-radius: 1.5em;
  text-transform: uppercase;
  padding: 5px 20px;
  cursor: pointer;
  border: solid;
  transition: background-color 0.5s ease, color 0.5s ease;
  height: 45px;
  align-items: center;
  user-select: none;
}

.button:not(:disabled):not(.disabled) {
  cursor: pointer;
  text-decoration: none;
}

.button-plein-clair {
  background-color: #DEA95B;
  border-color: #DEA95B;
  color: #ffffff;
}

.button-plein-clair:hover {
  background-color: #ffffff;
  border-color: #DEA95B;
  color: #DEA95B;
}

.button-vide-clair {
  background-color: #ffffff;
  border-color: #DEA95B;
  color: #DEA95B;
}

.button-vide-clair:hover {
  background-color: #DEA95B;
  border-color: #DEA95B;
  color: #ffffff;
}

.button-plein-fonce {
  background-color: #88330D;
  border-color: #88330D;
  color: #ffffff;
}

.button-plein-fonce:hover {
  background-color: #ffffff;
  border-color: #88330D;
  color: #88330D;
}

.button-vide-fonce {
  background-color: #ffffff;
  border-color: #88330D;
  color: #88330D;
}

.button-vide-fonce:hover {
  background-color: #88330D;
  border-color: #88330D;
  color: #ffffff;
}

.flat-input {
  border: none;
  background-color: inherit;
  outline: none;
  width: 40px;
  text-align: center;
  font-weight: bold;
  color: inherit;
  user-select: none;
  margin-bottom: 10px;
}

.quantity-button {
  min-width: 25px;
  text-align: center;
  font-weight: bold;
  font-size: clamp(25px, 0.68vw + 0.8rem, 2rem);
  line-height: clamp(25px, 0.68vw + 0.8rem, 2rem);
}

.label-quantity {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.button-menu {
  display: block;
  padding-top: 8px;
  margin-top: 25px;
}

body {
  margin: 0;
  padding: 0;
}

.nav-mobile {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  cursor: pointer;
  transition: 0.3s transform linear;
}
.nav-mobile:hover {
  transform: rotate(90deg);
}

.header {
  height: 50px;
  width: calc(100% - 10px);
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.header img {
  max-height: 40px;
}
.header svg {
  width: 20px;
  height: 20px;
}

.mobile-overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
}
.mobile-overlay.active {
  display: block;
}

.nav-mobile {
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #111827;
  padding: 50px 20px;
  transform: translateX(100%);
  transition: 0.4s transform ease-in-out;
}
.nav-mobile.active {
  transform: initial;
}
.nav-mobile-profil {
  text-align: center;
  border-bottom: 1px solid #485563;
  padding-bottom: 16px;
}
.nav-mobile-profil img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.nav-mobile-profil .name {
  color: #d1d5db;
  font-size: 18px;
  display: block;
  margin: 6px 8px;
}
.nav-mobile-profil .actions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 12px;
}
.nav-mobile-profil .actions a {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
}
.nav-mobile-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 24px;
  margin-bottom: 24px;
}
.nav-mobile-menu li {
  margin-bottom: 12px;
}
.nav-mobile-menu li:last-of-type {
  margin-bottom: initial;
}
.nav-mobile-menu li a {
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}

.menu {
  justify-content: space-around;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #ffffff;
}
.menu .desktop {
  display: none;
  flex-direction: row;
}
@media (min-width: 1015px) {
  .menu .desktop {
    flex-direction: row;
    display: flex;
  }
}
.menu .mobile {
  display: flex;
}
@media (min-width: 1015px) {
  .menu .mobile {
    flex-direction: column;
    display: none;
  }
}
.menu li a {
  text-decoration: none;
}
.menu-link {
  text-transform: uppercase;
  margin-top: 30px;
  position: relative;
  padding: 7.5px;
  display: block;
  font-weight: 700;
  height: 35px;
  text-decoration: none;
}
.menu-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #DEA95B;
  transform: scaleX(0);
  transition: 0.3s transform ease-in-out;
}
.menu-link:hover {
  color: #88330D;
}
.menu-link:hover:after {
  transform: scaleX(1);
}
.menu-link.selected {
  color: #88330D;
  font-weight: normal;
}
.menu-link.selected:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #88330D;
  transform: scaleX(1);
  transition: 0.3s transform ease-in-out;
}
.menu-link.selected:hover {
  color: #88330D;
}
.menu-link.selected:hover:after {
  transform: scaleX(0);
}
.menu-button {
  display: flex;
  flex-direction: row;
}
.menu-logo img {
  height: clamp(67px, 5.23vw + 46px, 113px);
}
.menu-burger {
  margin-top: 30px;
  position: relative;
  padding: 7.5px;
  font-weight: 700;
}
.menu-burger svg {
  height: clamp(13px, 2.73vw + 8px, 40px);
}
.menu-mobile {
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  width: 390px;
  height: 100vh;
  background-color: #ffffff;
  padding: 20px 0px;
  transform: translateX(200%);
  transition: 0.4s transform ease-in-out;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 15px;
}
.menu-mobile.active {
  transform: initial;
}
.menu-mobile li {
  list-style: none;
  display: flex;
  align-self: center;
}
.menu-mobile-logo {
  align-self: center;
}
.menu-mobile-logo img {
  height: clamp(67px, 5.23vw + 46px, 113px);
}
.menu-mobile-link {
  text-transform: uppercase;
  margin-top: 30px;
  position: relative;
  padding: 7.5px;
  display: block;
  font-weight: 700;
  width: 100%;
  align-self: center;
}
.menu-mobile-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #DEA95B;
  transform: scaleX(0);
  transition: 0.3s transform ease-in-out;
}
.menu-mobile-link:hover {
  color: #88330D;
  text-decoration: none;
}
.menu-mobile-link:hover:after {
  transform: scaleX(1);
}
.menu-mobile .button {
  min-width: 230px;
  text-align: center;
}
.menu-mobile-burger {
  position: absolute;
  display: block;
  top: 60px;
  right: 30px;
}
.menu-mobile-burger svg {
  height: clamp(13px, 2.73vw + 8px, 40px);
}
.menu-mobile .dropdown-item {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px;
  padding-bottom: 15px;
  align-self: center;
  width: 200px;
  text-align: center;
}

.acc-img {
  overflow: hidden;
  width: 100%;
}

.acc-container {
  background-color: #F4F1EA;
}

.acc-container-1 {
  display: flex;
  flex-direction: column-reverse;
  color: #88330D;
  margin-right: 0px;
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
}
@media (min-width: 1015px) {
  .acc-container-1 {
    flex-direction: row;
  }
}

.acc-text {
  padding: 0 2rem;
}
@media (min-width: 1015px) {
  .acc-text {
    flex-basis: 50%;
  }
}

.acc-text img {
  margin-top: 2rem;
}

.acc-img {
  position: relative;
}
@media (min-width: 1015px) {
  .acc-img {
    flex-basis: 50%;
  }
}

.acc-img img {
  object-fit: cover;
  width: 100%;
  height: 240px;
  overflow: hidden;
}
@media (min-width: 1015px) {
  .acc-img img {
    -webkit-clip-path: circle(100% at 100% 50%);
    clip-path: circle(90% at 100% 50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.acc-container-1 h1 {
  font-size: clamp(25px, 2.84vw + 13.64px, 3.1rem);
  font-weight: bold;
  font-style: italic;
  margin: 1em 0;
}

.acc-qui-sommes-nous {
  margin: 50px 0;
}

.bg-blanc {
  color: #88330D;
  background: #ffffff;
}
.bg-blanc h2 {
  color: #DEA95B;
}

.bg-fonce {
  color: #ffffff;
  background: #88330D;
}
.bg-fonce h2 {
  color: #DEA95B;
}

.bg-clair {
  color: #88330D;
  background: #DEA95B;
}
.bg-clair h2 {
  color: #ffffff;
}

.valeurs-bloc h2 {
  padding: 5% 20% 5% 0;
}
.valeurs-bloc img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reseaux-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  background-color: #88330D;
  color: #ffffff;
  font-size: clamp(25px, 0.68vw + 0.8rem, 2rem);
  width: 80%;
  margin: 0 auto;
  padding: 2rem 2rem 0 2rem;
  /* haut | droit | bas | gauche */
  border-radius: 40px;
}
.reseaux-container p {
  padding-bottom: 1.5rem;
}

.reseaux-col-gauche {
  width: 50%;
}

.reseaux-col-droite {
  width: 50%;
}

.reseaux-ligne-picto {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
  color: #DEA95B;
  font-size: clamp(12px, 0.23vw + 0.71rem, 1rem);
}

.lien {
  color: #ffffff;
  text-decoration: underline;
}

@media (max-width: 1015px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}
.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

/* medium and up screens */
@media (min-width: 1015px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }
  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}
.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

.ariane {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: center;
  font-size: 0.8rem;
}

.separator {
  margin: 0 5px;
}

.blog {
  background-color: #F4F1EA;
  padding-bottom: 100px;
}
.blog h1 {
  font-size: clamp(25px, 1.7vw + 0.8rem, 3rem);
  text-align: center;
  margin-bottom: 70px;
}
.blog-grid {
  display: flex;
  flex-flow: row wrap;
  gap: 26px;
  max-width: 1546px;
  margin: 0 auto;
  justify-content: center;
}

.article {
  background: #F4F1EA;
  padding-bottom: 80px;
}
.article-container {
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
  padding: 50px 0;
  color: #88330D;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.article-container h1 {
  font-size: clamp(25px, 1.7vw + 0.8rem, 3rem);
  font-weight: bold;
  font-style: italic;
  margin: 1em 0;
}
.article-cards {
  margin-left: 5%;
  margin-right: 5%;
}
.article-card {
  border: 1px solid transparent;
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 18px;
  margin: 0 12.5px 22px;
  max-width: 350px !important;
  height: 460px !important;
  font-size: clamp(12px, 0.23vw + 0.71rem, 1rem);
  font-weight: normal;
  font-style: normal;
}
.article-card img {
  width: 100%;
  height: 168px;
  border-radius: 23px;
}
.article-card-content {
  flex-grow: 1;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
}
.article-card-content .button {
  padding: 10px 20px;
  border-radius: 23px;
}
.article-card-title {
  font-size: clamp(17px, 0.23vw + 0.71rem, 1.3rem);
  margin-bottom: 0;
  font-weight: bold;
  font-style: italic;
  min-height: 60px;
  padding-top: 20px;
}
.article-card-excerpt {
  margin-top: 15px;
  margin-bottom: 33px;
  height: 4rem;
}
.article-card-link {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  color: #fff;
  text-decoration: none;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}
.article-card-text {
  height: 130px;
  padding-top: 20px;
}
.article-card-lire {
  align-self: center;
}
.article-full {
  margin: 0 auto;
  padding: 0 17px;
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
}
.article-full h1 {
  font-size: clamp(25px, 1.7vw + 0.8rem, 3rem);
  font-weight: bold;
  font-style: italic;
  margin-bottom: 1rem;
  font-family: "Literata", serif;
  text-align: center;
  margin: 50px 0;
}
.article-full img {
  width: 100%;
  max-height: 550px;
  object-fit: cover;
  margin-bottom: 1.5rem;
}
@media (max-width: 1015px) {
  .article-full img {
    border-radius: 23px;
  }
}
.article-full .article-meta {
  margin-bottom: 1.5rem;
}
.article-full .article-content {
  line-height: 1.8;
  margin-bottom: 150px;
}
.article-nav {
  margin: 1rem 0;
  text-transform: uppercase;
}
.article-nav img {
  width: 100%;
}
.article-nav .nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.picto {
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
}
.picto-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: white;
  color: #88330D;
  padding: 2rem;
  background-color: #ffffff;
}
.picto-container .picto-card {
  text-align: center;
}
.picto-container .picto-text {
  text-align: center;
  font-size: clamp(15px, 0.23vw + 0.71rem, 1.5rem);
  font-weight: bold;
  font-style: italic;
  width: 100%;
  font-family: "Lora", serif;
}
.picto-img {
  height: clamp(50px, 7.95vw + 18.18px, 120px);
}

.accueil-produits {
  background: #88330D;
  color: #ffffff;
  padding: 5% 0;
  text-align: center;
  justify-content: space-around;
}
.accueil-produits-carousel {
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
}
.accueil-produits-entete {
  padding-bottom: 2.5rem;
}
.accueil-produits-entete h1 {
  font-size: clamp(25px, 1.7vw + 0.8rem, 3rem);
  margin-bottom: 1.5rem;
}
.accueil-produits-entete p {
  margin-bottom: 1.5rem;
}
.accueil-produits-galler2 {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
}

.produits-container {
  color: #88330D;
  margin: auto;
  text-align: center;
  font-size: clamp(12px, 0.23vw + 0.71rem, 1rem);
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
}

.produits-faq h2 {
  text-align: left !important;
}

.produit-cards {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.slick-arrow {
  margin: 20px 0;
}

.produit-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 360px;
  max-width: 360px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 40px;
  padding: 17px;
  margin: 0 12.5px 22px;
  min-height: 612px;
  height: 612px;
}
.produit-card-accueil {
  min-width: 0;
  max-width: none;
}
.produit-card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.produit-card-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #88330D;
  font-weight: bold;
  font-size: clamp(17px, 0.23vw + 0.71rem, 1.3rem);
  text-align: center;
}
.produit-card-text {
  color: #88330D;
  height: calc(4.5 * clamp(17px, 0.23vw + 0.71rem, 1.3rem));
  text-align: left;
}
.produit-card-img-top {
  border-radius: 25px;
  max-width: 100%;
}
.produit-card-poidsprix {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.produit-card-poids {
  flex: 0 0 66%;
}
.produit-card-prix {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
}
.produit-card-apartirde {
  color: #88330D;
  font-size: clamp(12px, 0.23vw + 0.71rem, 1rem);
}
.produit-card-prix-prix {
  color: #88330D;
  font-size: clamp(15px, 0.23vw + 0.71rem, 1.5rem);
  font-weight: bold;
  font-style: italic;
}
.produit-card-bottom {
  margin-top: 10px;
}

.show-produit-carousel {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.abeille_produits {
  position: absolute;
  padding-top: 2%;
}

.produits-container h1 {
  font-size: clamp(25px, 1.7vw + 0.8rem, 4rem);
  font-style: italic;
  font-weight: bold;
}

.produits-suggestions {
  background-color: #DEA95B;
  padding-bottom: 100px;
}
.produits-suggestions-titre {
  font-size: clamp(25px, 1.7vw + 0.8rem, 3rem);
  font-weight: bold;
  font-style: italic;
  color: #fff;
  text-align: center;
  padding: 90px 0;
}
.produits-suggestions-container {
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
}

.produit_colonne_gauche {
  width: 670px;
  text-transform: uppercase;
}

.produit_colonne_droite {
  width: 100%;
}

.produit_colonnes {
  display: flex;
  flex-direction: row;
  max-width: 900px;
}

.produit_titre {
  font-size: clamp(25px, 1.7vw + 0.8rem, 3rem);
  font-weight: bold;
  font-style: italic;
}

.container_carousel_produits {
  display: flex;
  justify-content: center;
}

.carousel {
  max-width: 1680px;
}

.reseaux {
  background: #F4F1EA;
}
.reseaux-container {
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  background-color: #88330D;
  color: #ffffff;
  font-size: clamp(25px, 0.68vw + 0.8rem, 2rem);
  width: 80%;
  margin: 0 auto;
  border-radius: 40px;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .reseaux-container {
    flex-direction: column;
  }
}
.reseaux-ligne-picto {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  color: #DEA95B;
  font-size: clamp(12px, 0.23vw + 0.71rem, 1rem);
  flex-direction: row;
}
@media (max-width: 768px) {
  .reseaux-ligne-picto {
    flex-direction: column;
  }
}
.reseaux-titre {
  max-width: 650px;
}
.reseaux-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  padding-right: 2rem;
  max-width: 650px;
  margin: 0 auto;
  display: block;
}
.reseaux-ligne-picto-titre {
  text-transform: uppercase;
}
.reseaux-container p {
  padding-bottom: 1.5rem;
}
.reseaux-texte {
  flex-grow: 5;
  padding: 2rem;
}
.reseaux-image {
  flex-grow: 1;
}

.testimonials {
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  background: #72A23A;
  color: white;
  text-align: center;
}
.testimonials-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
}
.testimonials-img {
  display: block;
  width: 20%;
}
@media (max-width: 1015px) {
  .testimonials-img {
    display: none;
  }
}
.testimonials-img img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: fill;
}
.testimonials-content {
  width: 60%;
}
@media (max-width: 1015px) {
  .testimonials-content {
    width: 100%;
  }
}
.testimonials-content h1 {
  font-size: clamp(25px, 1.7vw + 0.8rem, 3rem);
  font-weight: bold;
  font-style: italic;
  height: 20%;
}

.testimonial-item {
  width: 100%;
  margin-top: 3rem;
}
.testimonial-carousel {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 50%;
}
.testimonial-signature {
  font-size: clamp(12px, 0.23vw + 0.71rem, 1rem);
  font-weight: bold;
  margin-top: 2rem;
}
.testimonial-text {
  font-size: 1.2rem;
  font-style: italic;
  margin: 10px 0;
  line-height: 1.5;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.num-hexa {
  position: relative;
  z-index: 1;
  font-size: clamp(25px, 2.84vw + 13.64px, 3.1rem);
  color: #FFF;
  font-weight: bold;
  font-style: italic;
  padding-right: 10px;
}
.num-hexa-container {
  position: relative;
  width: 125px;
  height: 100px;
  background-color: inherit;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.num-hexa-container-clair::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
  background-color: #DEA95B;
  /* Couleur du bord hexagonal */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.num-hexa-container-fonce::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
  background-color: #88330D;
  /* Couleur du bord hexagonal */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.valeurs-container-gauche {
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1015px) {
  .valeurs-container-gauche {
    flex-direction: column;
    padding: 40px 23px 40px 23px;
  }
}
.valeurs-container-droite {
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1015px) {
  .valeurs-container-droite {
    flex-direction: column-reverse;
    /* haut | droite | bas | gauche */
    padding: 40px 23px 40px 23px;
  }
}
@media (min-width: 1015px) {
  .valeurs-bloc-texte-gauche {
    /* haut | droite | bas | gauche */
    padding: 5% 7% 5% 2%;
  }
}
@media (min-width: 1015px) {
  .valeurs-bloc-texte-droite {
    padding: 5% 2% 5% 13%;
  }
}
.valeurs-nosvaleurs {
  background: #F4F1EA;
  color: #88330D;
  padding: 80px 10%;
  text-align: center;
}
.valeurs-nosvaleurs h1 {
  font-size: clamp(25px, 1.7vw + 0.8rem, 3rem);
}
.valeurs-bloc {
  flex: 0 0 50%;
}
@media (min-width: 1015px) {
  .valeurs-bloc-2 {
    padding-top: 28%;
  }
}
.valeurs-bloc-4 {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1015px) {
  .valeurs-bloc-4 img {
    height: auto !important;
    width: auto !important;
    max-width: 105%;
    transform: translateX(5%);
  }
}
@media (max-width: 1015px) {
  .valeurs-bloc img {
    height: 240px;
  }
}

.abeille-volante {
  position: absolute;
  padding-top: 2%;
}
@media (max-width: 1015px) {
  .abeille-volante {
    display: none;
  }
}